import React, { PureComponent } from 'react'
import { Zoom } from "react-awesome-reveal";

export default class objetivos extends PureComponent {
render() {
    return (
        <section className="section__container explore__container" id="objetivos" style={{ scrollMarginTop: '97px' }}>
        <Zoom>
        <div className="explore__header">
        <h2 className="section__header">NUESTROS <span style={{color:'orange'}}>OBJETIVOS</span></h2>
        </div>
        <div className="explore__grid">
            <div className="explore__card">
                <span></span>
                <h4>Presencia en Linea Solida</h4>
                <p>
                En la era digital, una presencia online sólida es esencial para el éxito. Con nosotros, construyes una base sólida en la web que perdura. 
                </p>
            </div>
            <div className="explore__card">
                <span></span>
                <h4>Aumentar la Visibilidad</h4>
                <p>
                Desde SEO hasta campañas publicitarias estratégicas, llevamos tu presencia a nuevas alturas.
                </p>
            </div>
            <div className="explore__card">
                <span></span>
                <h4>Compromiso de marca</h4>
                <p>
                Nos dedicamos a potenciar tu identidad y valores, hacemos que tu marca brille con autenticidad y relevancia.
                </p>
            </div>
            <div className="explore__card">
                <span></span>
                <h4>Fidelización</h4>
                <p>
                Construimos relaciones sólidas y duraderas con nuestros clientes, brindando experiencias excepcionales en cada interacción.
                </p>
            </div>
            <div className="explore__card">
                <span></span>
                <h4>Trato personalizado</h4>
                <p>
                Te ofrecemos un trato personalizado que se adapta a las necesidades específicas de tu negocio. 
                ¡tu éxito es nuestra prioridad!
                </p>
            </div>
        </div>
        </Zoom>
    </section>
    
    )
}
}
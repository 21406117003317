import React, { PureComponent } from 'react';
import Logo from '../assets/logoColor.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen
    }));
  }

  render() {
    const { isMenuOpen } = this.state;

    return (
      <>
        <nav className="nav">
          <div className="nav__logo">
          <a href="#"><img src={Logo} alt="logo" /></a>
          </div>
          <div className="nav__menu-icon" onClick={this.toggleMenu}>
            <i className={isMenuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
            <FontAwesomeIcon icon={faBars} />
          </div>
          <ul className={isMenuOpen ? 'nav__links open' : 'nav__links'}>
            <li className="link"><a href="#objetivos">Nuestros objetivos</a></li>
            <li className="link"><a href="#nosotros">Sobre nosotros</a></li>
            <li className="link"><a href="#planes">Paquetes</a></li>
            <li className="link"><a href="#contacto">Contacto</a></li>
          </ul>
          
          <a href="https://maps.app.goo.gl/J3WpdwKPknztzYW56" target="_blank"><button className="btn_header">Dónde encontrarnos</button></a>
        </nav>
      </>
    );
  }
}

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Formulario = () => {
  const form = useRef();
  const [showTick, setShowTick] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const textareaStyle = {
    resize: "none",
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fluf3cq",
        "template_cerkl4j",
        form.current,
        "EdMp1RitbnRiwCh98"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowTick(true);
          form.current.reset();

          setTimeout(() => {
            setShowTick(false);
          }, 3000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <>
      {showTick && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100px",
            height: "100px",
            backgroundColor: "green",
            borderRadius: "50%",
            textAlign: "center",
            lineHeight: "100px",
            color: "white",
            fontSize: "48px",
          }}
        >
          ✓
        </div>
      )}
      <form ref={form} onSubmit={sendEmail} className="gradient-border">
        <input type="text" name="nombre" placeholder="Nombre y apellidos" />
        <input type="email" name="email" placeholder="Correo" />
        <input type="tel" name="tlfno" placeholder="Teléfono" />
        <textarea
          name="mensaje"
          style={textareaStyle}
          placeholder="Mensaje"
        ></textarea>

        <div
          style={{
            margin: "20px 0",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#f8f8f8",
          }}
        >
          <p
            style={{
              fontSize: "12px", // Reduced font size here
            }}
          >
            <strong>Información sobre Protección de Datos</strong>
            <br />
            Responsable: Juan Manuel Pizarro López (JF PIMI) Finalidad:
            Ponernos en contacto contigo y atender las consultas y solicitudes
            de información que nos remitas a través de formularios habilitados
            en nuestra página web. Asimismo, para remitirte comunicaciones
            comerciales de productos y servicios comercializados por nuestra
            Entidad. Legitimación: Ejecución de medidas precontractuales a
            solicitud del interesado. Destinatarios: No se cederán sus datos a
            terceros, salvo obligación legal. Derechos: como titular de los
            datos usted tiene derecho a acceder, rectificar, suprimir y
            oponerse al tratamiento de sus datos, así como otros derechos,
            dirigiéndose a la dirección de correo electrónico
            jfpimic@gmail.com. Información Adicional: Puede consultar la
            información adicional y detallada sobre Protección de datos en
            nuestra página web, apartado de “Protección de datos”.
          </p>
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />{" "}
            He leído y acepto la política de protección de datos
          </label>
        </div>

        <input type="submit" value="Enviar" disabled={!isChecked} />
      </form>
    </>
  );
};

export default Formulario;

import React, { PureComponent } from 'react'
import { Zoom } from "react-awesome-reveal";

export default class negocios extends PureComponent {
render() {

    return (
      <div className='espacioNegocios'>
        <section className="section__container explore__container_negocios">
        <Zoom>
        <div class="join__image">
          <div class="join__grid">
            <div class="join__card">
              <span><i class="ri-user-star-fill"></i></span>
              <div class="join__card__content">
                <h4>Marketing Digital</h4>
                <p>Viaja al mundo digital con nuestras estrategias</p>
              </div>
            </div>
            <div class="join__card">
              <span><i class="ri-vidicon-fill"></i></span>
              <div class="join__card__content">
                <h4>Redes Sociales</h4>
                <p>Creamos comunidades digitales que perduran.</p>
              </div>
            </div>
            <div class="join__card">
              <span><i class="ri-building-line"></i></span>
              <div class="join__card__content">
                <h4>Publicidad Digital</h4>
                <p>Campañas creativas, conversiones garantizadas.</p>
              </div>
            </div>
          </div>
        </div>
        </Zoom>
    </section>
    </div>
    )
}
}
import React, { PureComponent } from 'react';
import Logo from '../assets/logoColor.png';
import ImagenFooter from '../assets/logo-negro.png';
import '../App.css'; // Asegúrate de importar tu archivo de estilos CSS
import Privacidad from './privacidad.jsx';
import Cookies from './cookies.jsx';

// Componente para los enlaces de las redes sociales
const SocialLinks = () => {
  return (
    <div className="footer__socials">
      <a href="https://www.facebook.com/profile.php?id=61556770483633"><i className="ri-facebook-fill"></i></a>
      <a href="https://www.instagram.com/jfpimic/?igsh=MTNkOWpuYm53aXV5ZQ%3D%3D"><i className="ri-instagram-line"></i></a>
      <a href="https://www.linkedin.com/in/jf-pimi-agencia-de-marketing-digital-y-community-manager-1a5917282/"><i className="ri-linkedin-fill"></i></a>
    </div>
  );
};

// Componente para los enlaces del footer
const FooterLinks = ({ title, links }) => {
  return (
    <div className="footer__col">
      <h4>{title}</h4>
      {links.map((link, index) => (
        <a key={index} href={link.url}>{link.texto}</a>
      ))}
    </div>
  );
};

// Datos estáticos para los enlaces del footer
const linksEmpresa = [
  { url: '#', texto: 'Inicio' },
  { url: '#objetivos', texto: 'Objetivos' },
  { url: '#planes', texto: 'Planes de pago' }
];

const linksContacto = [
  { url: 'tel:+34616889008', texto: '+34 616 88 90 08' },
  { url: 'tel:+34608051053', texto: '+34 608 05 10 53' },
  { url: 'mailto:jfpimic@gmail.com', texto: 'jfpimic@gmail.com' },
  { url: 'https://maps.app.goo.gl/J3WpdwKPknztzYW56', texto: 'Ubicación' }
];

const linksPoliticas = [
  { texto: 'Política de privacidad', componente: <Privacidad /> },
  { texto: 'Política de cookies', componente: <Cookies /> }
];

export default class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mostrarBanner: false,
      contenidoBanner: null
    };
  }

  mostrarBanner = (contenido) => {
    this.setState({
      mostrarBanner: true,
      contenidoBanner: contenido
    });
  };

  ocultarBanner = () => {
    this.setState({
      mostrarBanner: false,
      contenidoBanner: null
    });
  };

  render() {
    return (
      <>
        <footer className="section__container footer__container">
          <span className="bg__blur"></span>
          <span className="bg__blur footer__blur"></span>
          <div className="footer__col">
            <div className="footer__logo"><img src={Logo} alt="logo" /></div>
            <SocialLinks />
          </div>
          <FooterLinks title="Empresa" links={linksEmpresa} />
          <FooterLinks title="Contacto" links={linksContacto} />
          <div className="footer__col">
            <h4>Políticas</h4>
            {linksPoliticas.map((link, index) => (
              <a key={index} href="#" onClick={(e) => { e.preventDefault(); this.mostrarBanner(link.componente); }}>{link.texto}</a>
            ))}
          </div>
        </footer>
        {/* Código para el banner */}
        {this.state.mostrarBanner && (
          <div className="overlay" onClick={this.ocultarBanner}>
            <div className="banner" onClick={(e) => e.stopPropagation()}>
              <button className="cerrar" onClick={this.ocultarBanner}>Cerrar</button>
              {this.state.contenidoBanner}
            </div>
          </div>
        )}
        <div className="footer__bar">
          <p>Copyright © 2024 JF PIMI </p>
          <p className="footer__image" style={{ display: "inline-block", float: "right" }}> <a href="https://noos-solutions.es/" style={{ display: "inline-block", alignItems: "right", float: "right" }}> <img src={ImagenFooter} alt="logo noos" /> </a></p>
        </div>
      </>
    );
  }
}

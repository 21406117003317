import React, { PureComponent } from 'react'

export default class planes extends PureComponent {
  render() {
    return (
        <section class="section__container price__container" id="planes" style={{ scrollMarginTop: '100px' }}>
        <h2 class="section__header"> <span style={{color:'orange'}}>PLANES</span> DE PAGO</h2>

        <p class="section__subheader">
        Trabajamos de forma personalizada con cada cliente, teniendo en cuenta los resultados de la 
        estrategias y las valoraciones 
        </p>
        <p class="section__subheader2" style={{color:'orange'}}>
        Tus clientes son los nuestros.
        </p>
        <div class="price__grid">
          <div class="price__card">
            <div class="price__card__content">
              
              <h3>PACK BÁSICO</h3>
              <p>
                <i class="ri-checkbox-circle-line"></i>
                HASTA 2 PLATAFORMAS SOCIALES A GESTIONAR
              </p>
              <p>
                <i class="ri-checkbox-circle-line"></i>
                DISEÑO DE ESTRATEGIA DE CONTENIDO
              </p>
              <p>
                <i class="ri-checkbox-circle-line"></i>
                FRECUENCIA MEDIA DE PUBLICACIONES:
              </p>
              <p>
                <i class="ri-checkbox-blank-circle-line"></i>
                2 - 3 POST (SEMANA)
              </p>
              <p>
                <i class="ri-checkbox-blank-circle-line"></i>
                2 - 3 HISTORIAS (SEMANA)
              </p>
              <p>
                <i class="ri-checkbox-circle-line"></i>
                INFORME DE CONTENIDO MENSUAL
              </p>
            </div>
          </div>
          <div class="price__card2">
            <div class="price__card__content2">
              <h3>PACK PREMIUM</h3>
              <p>
              <i className="ri-checkbox-circle-line" style={{ color: 'black' }}></i>
                DESDE 2 PLATAFORMAS SOCIALES A GESTIONAR
              </p>
              <p>
              <i className="ri-checkbox-circle-line" style={{ color: 'black' }}></i>                DISEÑO DE ESTRATEGIA DE CONTENIDO
              </p>
              <p>
              <i className="ri-checkbox-circle-line" style={{ color: 'black' }}></i>                FRECUENCIA DE PUBLICACIONES:
              </p>
              <p>
              <i className="ri-checkbox-blank-circle-line" style={{ color: 'black' }}></i>                5 - 7 POST (SEMANA)
              </p>
              <p>
                <i class="ri-checkbox-blank-circle-line" style={{ color: 'black' }}></i>
                5 - 7 HISTORIAS (SEMANA)
              </p>
              <p>
              <i className="ri-checkbox-circle-line" style={{ color: 'black' }}></i>                PUBLICACIONES FECHAS ESPECIALES
              </p>
              <p>
              <i className="ri-checkbox-circle-line" style={{ color: 'black' }}></i>                INFORME DE CONTENIDO MENSUAL
              </p>
            </div>
          </div>
          <div class="price__card">
            <div class="price__card__content">
              <h3>EXTRAS</h3>
              <p>
                <i class="ri-computer-line"></i>
                CREACIÓN REDES SOCIALES
              </p>
              <p>
                <i class="ri-mail-line"></i>
                CREACIÓN CORREO ELECTRÓNICO PARA GESTIÓN DE REDES
              </p>
              <p>
                <i class="ri-dropdown-list"></i>
                MANTENIMIENTO Y PLANIFICACIÓN DE ESTRUCTURA Y FEED
              </p>
              <p>
                <i class="ri-hand"></i>
                PERFILES SOCIALES ADAPTADOS
              </p>
              <p>
                <i class="ri-space-ship-line"></i>
                PUBLICACIONES EN FECHAS ESPECIALES
              </p>
              <p>
                <i class="ri-camera-line"></i>
                FORMACIÓN EN FOTOS A PRODUCTOS
              </p>
              <p>
                <i class="ri-camera-3-line"></i>
                VIDEOS PROFESIONALES O SPOT PUBLICITARIOS
              </p>
              <p>
                <i class="ri-shopping-cart-line "></i>
                PAGINAS WEB (PROXIMAMENTE)
              </p>
            </div>
          </div>
        </div>
        <div className='containerSolicitaPresupuesto'>
        <button className="btn_solicitaPresupuesto" onClick={() => document.getElementById('contacto').scrollIntoView({ behavior: 'smooth' })}>¡SOLICITA TU PRESUPUESTO!</button>
        </div>
      </section>
    )
  }
}

import React, { PureComponent } from 'react'
import ImagenPrincipal from '../assets/portada.png';
import { Fade } from "react-awesome-reveal";

export default class inicio extends PureComponent {
  render() {
    return (
        <header className="section__container header__container">
        <Fade>
        <div className="header__content">
          <span className="bg__blur"></span>
          <span className="bg__blur header__blur"></span>
          <p className='txtAgencia' >AGENCIA DE MARKETING Y COMMUNITY MANAGER</p>
          <h1><span>TÚ PUEDES</span> LOGRARLO</h1>
          <p>
          Nos encargamos de que tu empresa <span style={{ color: 'orange', fontWeight: 'bold' }}> DESTAQUE </span>
          frente a la competencia <span style={{ color: 'orange', fontWeight: 'bold' }}> DIFERENCIÁNDOTE </span> en tu sector.
          </p>
          <button className="btn_nosotrosTeAyudamos" onClick={() => document.getElementById('contacto').scrollIntoView({ behavior: 'smooth' })}>Nosotros te ayudamos</button>
        </div>
        <div className="header__image">
          <img src={ImagenPrincipal} alt="CM" />
        </div>
        </Fade>
      </header>
    )
  }
}

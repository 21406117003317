import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Foto1 from '../assets/foto1.jpg';
import Foto2 from '../assets/foto2.jpg';
import Foto3 from '../assets/foto3.jpg';
import Foto4 from '../assets/foto4.jpg';
import Foto5 from '../assets/foto5.jpg';

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Slider {...settings}>
        <div>
          <img src={Foto1} alt="Slide 1" style={{width: '100%'}} />
        </div>
        <div>
          <img src={Foto2} alt="Slide 2" style={{width: '100%'}} />
        </div>
        <div>
          <img src={Foto3} alt="Slide 3" style={{width: '100%'}} />
        </div>
        <div>
          <img src={Foto4} alt="Slide 4" style={{width: '100%'}} />
        </div>
        <div>
          <img src={Foto5} alt="Slide 5" style={{width: '100%'}} />
        </div>
      </Slider>
    </div>
  );
};


// Componentes personalizados para las flechas
const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ position: 'absolute', left: 0, zIndex: 1, fontSize:'32px'}}>
    </div>
  );
}

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ position: 'absolute', right: 0, zIndex: 1 }}>
    </div>
  );
}

export default Carousel;

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import '../App.css'; // Asegúrate de que este archivo está correctamente importado
import CookieInfo from './cookies.jsx';

const CookieBanner = ({ onAccept }) => {
  const [accepted, setAccepted] = useState(false);
  const [mostrarBanner, setMostrarBanner] = useState(false);
  const [contenidoBanner, setContenidoBanner] = useState(null);
  const [showReopenButton, setShowReopenButton] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (consent) {
      setAccepted(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    Cookies.set('cookieConsent', true, { expires: 365 });
    Cookies.set('functionalCookies', true, { expires: 365 });
    Cookies.set('marketingCookies', true, { expires: 365 });
    Cookies.set('personalizationCookies', true, { expires: 365 });
    Cookies.set('socialCookies', true, { expires: 365 });
    Cookies.set('statisticsCookies', true, { expires: 365 });
    Cookies.set('technicalCookies', true, { expires: 365 });

    onAccept();
    setAccepted(true);
    setShowReopenButton(false);
  };

  const handleRejectCookies = () => {
    Cookies.remove('functionalCookies');
    Cookies.remove('marketingCookies');
    Cookies.remove('personalizationCookies');
    Cookies.remove('socialCookies');
    Cookies.remove('statisticsCookies');

    Cookies.set('technicalCookies', true, { expires: 365 });

    onAccept();
    setAccepted(false);
    setShowReopenButton(true); // Mostrar el botón de reabrir el banner
  };

  const handleReopenBanner = () => {
    setShowReopenButton(false);
    setAccepted(false);
  };

  const linksPoliticas = [
    { texto: 'Pincha aquí para obtener más información', componente: <CookieInfo /> }
  ];

  const mostrarPolitica = (componente) => {
    setContenidoBanner(componente);
    setMostrarBanner(true);
  };

  const ocultarBanner = () => {
    setMostrarBanner(false);
    setContenidoBanner(null);
  };

  return (
    <>
      {!accepted && !showReopenButton && (
        <div className="cookie-banner">
          <h2 style={{ display: 'inline-block' }}>Esta página web usa cookies</h2>
          <span className="cookie-emoji" role="img" aria-label="cookie" style={{ display: 'inline-block' }}>🍪</span>
          <p style={{ lineHeight: '2em', marginBottom: '1em' }}>
            Te informamos que el presente sitio web utiliza cookies propias y de terceros con las siguientes finalidades: Técnicas que permiten el buen funcionamiento de la web y ofrecerte una experiencia personalizada. De personalización, que si lo autorizas, recordarán tus preferencias en el sitio web. Estadísticas, que si lo autorizas, analizarán tu visita al sitio web con fines estadísticos. De marketing o publicidad comportamental las cuales analizarán tu visita al sitio web con la finalidad de analizar tu perfil. Puedes aceptar el uso de todas las cookies pulsando el botón <span style={{ color: 'orange', fontWeight: 'bold' }}> ACEPTAR </span> y para rechazar todas las cookies opcionales puede pulsar el botón <span style={{ color: 'orange', fontWeight: 'bold' }}> RECHAZAR </span>.
            <br></br>
            <button className="cookie-button" onClick={handleAcceptCookies}>Aceptar Cookies</button>
            <button className="cookie-button" onClick={handleRejectCookies}>Rechazar Cookies</button>
          </p>
          <div className="footer__col">
            {linksPoliticas.map((link, index) => (
              <a key={index} href="#" onClick={(e) => { e.preventDefault(); mostrarPolitica(link.componente); }}>{link.texto}</a>
            ))}
          </div>
          {mostrarBanner && (
            <div className="overlay" onClick={ocultarBanner}>
              <div className="banner" onClick={(e) => e.stopPropagation()}>
                <button className="cerrar" onClick={ocultarBanner}>Cerrar</button>
                {contenidoBanner}
              </div>
            </div>
          )}
        </div>
      )}
      {showReopenButton && (
        <button
        className="cookie-reopen-button"
        onClick={handleReopenBanner}
        style={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          padding: '10px 20px',
          backgroundColor: 'orange',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          zIndex: 1000 // Asegúrate de que el botón esté encima de otros elementos
        }}
      >
        Configurar Cookies
      </button>
      
      )}
    </>
  );
};

export default CookieBanner;

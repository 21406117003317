import './App.css';
import Header from './componentes/header';
import Inicio from './componentes/inicio';
import Objetivos from './componentes/objetivos';
import Negocios from './componentes/negocios';
import Diferenciate from './componentes/diferenciate';
import Nosotros from './componentes/nosotros';
import Planes from './componentes/planes';
import Contacto from './componentes/contacto';
import Footer from './componentes/footer';
import { useState, useEffect } from "react";
import HashLoader from "react-spinners/HashLoader";
import CookieBanner from './componentes/bannerCookies';

function App() {
  const [loading, setLoading] = useState(true); // Por defecto, muestra el spinner de carga
  const [showCookieBanner, setShowCookieBanner] = useState(true); // Controla la visibilidad del banner de cookies

  useEffect(() => {
    // Simula una carga de 3 segundos
    setTimeout(() => {
      setLoading(false); // Oculta el spinner después de 3 segundos
    }, 3000);
  }, []);

  const handleAcceptCookies = () => {
    setShowCookieBanner(false); // Cuando el usuario acepta las cookies, oculta el banner de cookies
    // Aquí puedes almacenar la aceptación de cookies en localStorage o en una cookie para recordar la elección del usuario.
  };

  return (
    <div className="App">
      {loading ? (
        <HashLoader color="#d79447" className='loader'/>
      ) : (
        <div>
          <Header />
          <Inicio />
          <Objetivos />
          <Negocios />
          <Diferenciate />
          <Nosotros />
          <Planes />
          <Contacto />
          <Footer />
          {showCookieBanner && <CookieBanner onAccept={handleAcceptCookies} />} {/* Muestra el banner de cookies solo si showCookieBanner es true */}
        </div>
      )}
    </div>
  );
}

export default App;

import React from 'react';
import Foto1 from '../assets/class-4.jpg';
import Foto2 from '../assets/class-3.jpg';

const ClassContainer = () => {
  return (
    <section className="section__container class__container" id="clases">
      <div className="class__image">
        <span className="bg__blur"></span>
        <img src={Foto1} alt="class" className="class__img-1" />
        <img src={Foto2} alt="class" className="class__img-2" />
      </div>
      <div className="class__content">
        <h2 className="section__header">DIFERÉNCIATE</h2>
        <p classname="textoDiferenciate">
            En <span style={{color:'orange'}}>JF PIMI</span> trabajamos con <span style={{color:'orange'}}>numerosas estrategias de marketing y servicios especializados en redes sociales</span> para conseguir que tu empresa se <span style={{color:'orange'}}>diferencie del resto y destaque</span>.
        </p>
        <button className="btn_diferenciate" onClick={() => document.getElementById('contacto').scrollIntoView({ behavior: 'smooth' })}>Contacta con nosotros</button>
      </div>
    </section>
  );
};

export default ClassContainer;
import React, { PureComponent } from 'react'
import Carrusel from './carrusel';

export default class nosotros extends PureComponent {
  render() {
    return (
        <section class="section__container join__container" id="nosotros" style={{ scrollMarginTop: '100px' }}>
        <h2 class="section__header">SOBRE <span style={{color:'orange'}}>NOSOTROS</span></h2>
        <p class="section__subheader">
        En <span style={{color:'orange'}}>JF Pimi</span>, fusionamos la creatividad con la experiencia en marketing digital. 
        Liderada por <span style={{color:'orange'}}>Juanma Pizarro</span> y <span style={{color:'orange'}}>Fran Millán</span>, nuestra misión es impulsar el crecimiento de tu
        empresa y crear una sólida fidelización de clientes. Nos mantenemos en constante
        desarrollo y aprendizaje, siempre al tanto de las últimas estrategias del mercado,
        para llevar a tu negocio al <span style={{color:'orange'}}>éxito</span> sin igual.
        </p>
        <br></br>
        <Carrusel></Carrusel>
      </section>
    )
  }
}
